<template>
  <TheHeader></TheHeader>
  <div id="container" class="container container--fit">
    <div id="main" class="main">
      <!-- introduce -->
      <section class="introduce" v-if="mhm0101.length > 0">
				<div class="gallery__slider">
					<swiper @slideChange="setSlideCurrentIndex" data-currentnum=".gallery__currentnum--1"
					        :autoplay='{"delay": 3000, "disableOnInteraction": false}' :loop="mhm0101.length === 1 ? false : true">
						<swiper-slide>
							<div class="introduce__image noneimage">
                <div class="imagebg" 
                  v-if="!isLogin"
                 :style="{
                    'background-image': 'url(\'' + mhm0101[0].hm_img + '\')',
                   'background-size': '100% 100%'
					        }" 
                />
                <div class="imagebg" 
                  v-else
                  :style="{
                   'background-image': 'url(\'' + mhm0101[0].hm_img_login + '\')',
                    'background-size': '100% 100%'
			      		  }" 
                />
							</div>
						</swiper-slide>
					</swiper>
				</div>
				<div class="gallery__meta">
					<span></span>
					<span class="gallery__badge">
						<strong class="gallery__currentnum gallery__currentnum--1">{{mhm0101.length}}</strong>
						<i class="gallery__dot"></i>
						<small>{{mhm0101.length}}</small>
					</span>
        </div>
      </section>
      <!-- main search -->
      <section class="search">
        <div class="introduce__description">
          <!-- [REST] 닉네임 호출, 문구 변경 가능 -->
          <!-- [D] 로그인 전 표시 -->
          <h2 class="introduce__title" v-if="mhm0101.length > 0">
            <template v-if="!isLogin">
              {{mhm0101[0].hm_logoff_title.split("로그인")[0]}}<router-link to="/layers/login"><strong>{{t('10685')}}</strong></router-link>{{mhm0101[0].hm_logoff_title.split("로그인")[1]}}
            </template>
            <template v-else>
              <b>{{userData.nick_nm}}</b>{{mhm0101[0].hm_login_title}}
            </template>
          </h2>
          <!-- // -->
        </div>
        <div class="box">
          <div class="search__wrap">
            <!-- [D] 선택되기 전 -->
            <ul class="search__list">
              <li>
                <router-link to="/layers/location">
                  <div class="search__inner">
                    <i class="icon icon-location">{{t('10585')}}</i>
                    <input
                      type="text"
                      class="search__ipt"
                      :class="{ active: selectedLocation !== null }"
                      readonly
                      :placeholder="
                        selectedLocation ? undefined : t('10838')
                      "
                      :value="
                        selectedLocation
                          ? t('area'+selectedLocation.area_cd2)
                          : undefined
                      "
                    />
                    <i class="icon icon-right--light"></i>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link to="/layers/date">
                  <div class="search__inner">
                    <i class="icon icon-date">{{t('10586')}}</i>
                    <input
                      type="text"
                      class="search__ipt"
                      :class="{ active: dateRangeText !== null }"
                      readonly
                      :placeholder="
                        dateRangeText ? undefined : t('10839')
                      "
                      :value="dateRangeText ? dateRangeText : undefined"
                    />
                    <i class="icon icon-right--light"></i>
                  </div>
                </router-link>
              </li>
              <li :class="{ active: selectedMember !== null }">
                <router-link to="/layers/member">
                  <div class="search__inner">
                    <i class="icon icon-user">{{t('10587')}}</i>
                    <div
                      v-if="selectedMember !== null"
                      class="search__resultmember"
                    >
                      <span class="search__text">{{t('10588')}} {{ selectedMember.adultCount }}</span>
                      <template v-if="selectedMember.childCount > 0">
                        <span class="search__dot"></span>
                        <span class="search__text">{{t('10589')}} {{ selectedMember.childCount }}</span>
                      </template>
                    </div>
                    <div class="search__ismember">
                      <input
                        type="text"
                        class="search__ipt"
                        :class="{ active: selectedMember !== null }"
                        readonly
                        :placeholder= "t('10837')"
                      />
                    </div>
                    <i class="icon icon-right--light"></i>
                  </div>
                </router-link>
              </li>
            </ul>
            <!-- // -->
            <div class="search__submit">
              <button type="button" class="btn-mysearch" @click="findHotel">
                {{t('10629')}}
              </button>
            </div>
          </div>
        </div>
      </section>
      <!-- weather -->
      <section class="weather">
        <div class="box">
          <div class="weather__wrap">
            <!-- [D] 지역 선택 전 -->
            <router-link to="/layers/login" v-if="!isLogin">
              <h2 class="weather__title" v-if="isLogin">
                <i class="icon icon-compass"></i>
                <span>{{t('10630')}}</span>
                <i class="icon icon-down--light"></i>
              </h2>
            </router-link>
            <template v-else-if="weather.length > 0"> 
              <h2 class="weather__title active" @click="handleWeatherLocationsOpen()">
                <i class="icon icon-compass"></i>
                <span>{{t('weather'+selectedWeatherLocation.langcd)}}</span>
                <i class="icon" :class="'icon-' + (weatherLocationsOpen ? 'up' : 'down')"></i>
              </h2>
              <div class="weather__locations" v-if="weatherLocationsOpen">
                <button 
                  v-for="weatherLocation in weatherLocations"
                  :key="weatherLocation.regId"
                  class="weather__button"
                  :class="{active: selectedWeatherLocation.regId === weatherLocation.regId}"
                  type="button"
                  @click="handleWeatherLocationClick(weatherLocation)"
                >
                  {{t('weather'+weatherLocation.langcd)}}
                </button>
              </div>
              <!-- // -->
              <ul class="weather__list">
                <li v-for="weatherItem in weather" :key="weatherItem.date">
                  <small>{{t("day"+weatherItem.dayOfTheWeek)}}</small>
                  <i class="icon icon-weather" :class="'icon-weather--' + weatherItem.id">{{weatherItem.text}}</i>
                  <span>{{weatherItem.date}}</span>
                </li>
              </ul>
            </template>
          </div>
        </div>
      </section>
      <!-- content -->
      <section class="content">
        <div class="item">
          <template v-if="popularRecommend.length > 0 && isLogin">
            <h2 class="title">
              {{t('10631')}}
            </h2>
            <div class="item__body">
              <div class="carousel">
                <!-- [REST] 현재 기준 가까운 상품 10개 호출 -->
                <div class="carousel__slider">
                  <swiper :slides-per-view="1.4" :space-between="16">
                    <swiper-slide v-for="room in popularRecommend" :key="room.hotel_no">
                      <router-link :to="'/rooms/' + room.hotel_no" class="carousel__link">
                        <div class="carousel__image noneimage">
                          <div class="imagebg" :style="{'background-image': 'url(\'' + room.img_url + '\')'}" />
                        </div>
                        <div class="carousel__info">
                          <div class="carousel__slogan info-slogan" v-if="lang === 'ko'">
                            {{room.hotel_desc}}
                          </div>
                          <div class="carousel__slogan info-slogan"  v-else>
                            {{room.hotel_desc_E}}
                          </div>
                          <h3 class="carousel__title info-title" v-if="lang === 'ko'">
                            {{room.hotel_nm}}
                          </h3>
                          <h3 class="carousel__title info-title" v-else>
                            {{room.hotel_enm}}
                          </h3>
                          <div class="carousel__grad info-grad">
                            <span class="carousel__icon info-icon">
                              <i class="icon icon-star"></i>
                            </span>
                            <span class="carousel__gradnum info-gradnum">
                              {{room.score.toFixed(1)}}
                            </span>
                            <span class="carousel__gradcounter info-gradcounter">
                            ({{numberWithCommas(room.reviews)}})
                          </span>
                          </div>
                          <div class="carousel__price">
                            <span class="carousel__day">
                              {{t('10607')}}
                            </span>
                            <span class="carousel__saleprice" v-if="room.DCRATE_NIGHT > 0">
                              {{room.DCRATE_NIGHT}}%
                            </span>
                            <span class="carousel__currentprice">
                              <strong>{{numberWithCommas(room.SALESAMT_NIGHT)}}</strong>{{t('10088')}}
                            </span>
                          </div>
                        </div>
                      </router-link>
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="item">
          <div class="card" v-if="mhm0102.length > 0 && mhm0102[0].INTRO.length > 0">
            <div class="card__wrap">
              <router-link :to="mhm0102[0].NEXTPAGE" class="card__link">
                <div class="card__inner">
                  <h2 class="card__title" style="font-size:14px;color:#8B8B8B;"
                    v-html="mhm0102[0].INTRO.split('\\n').join('<br />')">
                  </h2>
                  <div class="card__icon">
                    <i class="icon icon1-caravan"></i>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <!-- // -->
        <!-- [REST] 이후 카드형태 모두 피드 컨텐츠 호출 -->
        <div 
          class="item"
          v-for="item in mfd01011.slice(0, 3)"
          :key="item.FEED_NO"
        >
          <div v-if="usefeed === 'Y'" class="card card--feed">
            <div class="card__wrap">
              <router-link :to="'/feed/' + item.FEED_NO" class="card__link">
                <div class="card__image noneimage">
									<div class="imagebg" :style="{'background-image': 'url(\'' + item.IMG_URL + '\')'}" />
                </div>
                <h2 class="card__title">
                  {{ item.FEED_NM }}
                </h2>
                <p class="card__description">
                  {{ item.FEED_SUB }}
                </p>
              </router-link>
            </div>
          </div>
        </div>
        <div class="item" v-if="mev0104.length > 0">
          <div class="card bg">
            <div class="card__badge badge badge--notice">
              <span class="badge__text"> {{t('10062')}} </span>
            </div>
            <!-- [REST] 이벤트 최신순으로 호출 -->
            <div class="card__slider" v-if="mev0104.length > 0">
              <!-- [D] scrollbar의 el 항목으로 슬라이더 다음에 있는 progress__bar의 클래스 이름 지정-->
              <swiper :scrollbar="{ el: '.progress__bar--1' }">
                <swiper-slide v-for="event in mev0104" :key="event.EV_NO">
                  <router-link :to="'/event/event_detail/' + event.EV_NO" class="card__link">
                    <div class="card__image noneimage">
									    <div class="imagebg" :style="{'background-image': 'url(\'' + event.EVENT_PIC1 + '\')'}" />
                    </div>
                  </router-link>
                </swiper-slide>
              </swiper>
            </div>
            <div v-if="eventList.length > 1" class="progress">
              <div class="progress__bar progress__bar--1"></div>
            </div>
          </div>
        </div>
        <div 
          class="item"
          v-for="item in mfd01011.slice(3, 6)"
          :key="item.FEED_NO" 
        >
          <div class="card card--feed" v-if="usefeed === 'Y'">
            <div class="card__wrap">
              <router-link :to="'/feed/' + item.FEED_NO" class="card__link">
                <div class="card__image noneimage">
									<div class="imagebg" :style="{'background-image': 'url(\'' + item.IMG_URL + '\')'}" />
                </div>
                <h2 class="card__title">
                  {{ item.FEED_NM }}
                </h2>
                <p class="card__description">
                  {{ item.FEED_SUB }}
                </p>
              </router-link>
            </div>
          </div>
        </div>
        <div class="item" v-if="mhm0102.length > 0 && mhm0102[0].INTRO2.length > 0">
          <div class="card card--feed">
            <div class="card__wrap">
              <router-link :to="mhm0102[0].NEXTPAGE2"  class="card__link">
                <div class="card__inner">
                  <h2 class="card__title" style="font-size:14px;color:#8B8B8B;" v-html="mhm0102[0].INTRO2">
                  </h2>
                  <p
                    class="card__description"
                    v-html="mhm0102[0].MSG2.split('\\n').join('<br />')"
                  ></p> 
                  <div class="card__icon">
                    <i class="icon icon1-hotel"></i>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div
          class="item"
          v-for="item in mfd01011.slice(6, 9)"
          :key="item.FEED_NO"
        >
          <div class="card card--feed">
            <div class="card__wrap">
              <router-link :to="'/feed/' + item.FEED_NO" class="card__link">
                <div class="card__image noneimage">
                  <div class="imagebg" :style="{'background-image': 'url(\'' + item.IMG_URL + '\')'}" />
                </div>
                <h2 class="card__title">
                  {{ item.FEED_NM }}
                </h2>
                <p class="card__description">
                  {{ item.FEED_SUB }}
                </p>
              </router-link>
            </div>
          </div>
        </div>
        <div class="item"  v-if="alleventList.length > 0">
          <div class="card">
            <!-- [D] 뱃지 있을 시 추가 -->
            <div class="card__badge badge badge--plan">
              <span class="badge__text"> {{t('10066')}} </span>
            </div>
            <!-- [REST] 기획전 최신순으로 호출 -->
            <div class="card__slider" v-if="alleventList.length > 0">
              <!-- [D] scrollbar의 el 항목으로 슬라이더 다음에 있는 progress__bar의 클래스 이름 지정-->
              <swiper :scrollbar="{ el: '.progress__bar--2' }">
                <swiper-slide v-for="plan in alleventList" :key="plan.PROMO_NO">
                  <router-link :to="'/event/' + plan.PROMO_NO" class="card__link">
                    <div class="card__image noneimage">
                      <div class="imagebg" :style="{'background-image': 'url(\'' + plan.IMG_URL + '\')'}" />
                    </div>
                  </router-link>
                </swiper-slide>
              </swiper>
              <div  v-if="alleventList.length > 1" class="progress">
                <div class="progress__bar progress__bar--2"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="item" v-if="mhm0102.length > 0 && mhm0102[0].INTRO3.length > 0">
          <div class="card">
            <div class="card__wrap">
              <router-link :to="mhm0102[0].NEXTPAGE3"  class="card__link">
                <div class="card__inner">
                  <h2 class="card__title" style="font-size:14px;color:#8B8B8B;" v-html="mhm0102[0].INTRO3">
                  </h2>
                  <!-- <p
                    class="card__description"
                    v-html="mhm0102[0].MSG3.split('\\n').join('<br />')"
                  ></p> -->
                  <div class="card__icon">
                    <i class="icon icon1-ticket"></i>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div
          class="item"
          v-for="item in mfd01011.slice(9, 12)"
          :key="item.FEED_NO"
        >
          <div class="card card--feed">
            <div class="card__wrap">
              <router-link :to="'/feed/' + item.FEED_NO" class="card__link">
                <div class="card__image noneimage">
                  <div class="imagebg" :style="{'background-image': 'url(\'' + item.IMG_URL + '\')'}" />
                </div>
                <h2 class="card__title">
                  {{ item.FEED_NM }}
                </h2>
                <p class="card__description">
                  {{ item.FEED_SUB }}
                </p>
              </router-link>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div class="skeleton" :class="{hide: completed}">
		<div class="skeleton__bg"></div>
	</div>
  <TheFooter></TheFooter>
  <TheNavigation></TheNavigation>
  <Toast v-if="openToast" :type="'check'" :handleClose="handleCloseToast" :closeSecond="5"/>
  <TheTop/>
</template>
<style>
	body{height:auto}
	html{height:auto !important;}
	#app{height:auto}
</style>
<script >
import { useStore } from "vuex";
import { computed, onMounted, ref, watch, watchEffect } from "vue";
import numberWithCommas from "@/utils/numberWithCommas";
import mhm021 from "@/urls/mhm021";
import Toast from "@/components/Toast";
import dayjs from "dayjs";
import i18n from "@/i18n"; 
import "dayjs/locale/ko";
import "dayjs/locale/en";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import { useRouter } from 'vue-router';

dayjs.locale("ko");
dayjs.locale("en");
export default {
  methods: {
		/* 현재 슬라이드 넘버 지정하기 */
		setSlideCurrentIndex: (swiper) => {
			var target = swiper.$el[0].getAttribute('data-currentnum'),
				index = swiper.realIndex;
			document.querySelector(target).innerHTML = index + 1;
		}
	},
  components: {
    Toast,
  },
  setup() {    
    const weatherTextList = ["", "맑음","구름많음","흐림","비","눈","눈/비"];
    const weatherLocations = [
      {name: "서울·경기", regId: "11B00000",langcd:"00"},
      {name: "강원", regId: "11D10000",langcd:"01"},
      {name: "충남", regId: "11C20000",langcd:"02"},
      {name: "충북", regId: "11C10000",langcd:"03"},
      {name: "전남", regId: "11F20000",langcd:"04"},
      {name: "전북", regId: "11F10000",langcd:"05"},
      {name: "경북", regId: "11H10000",langcd:"06"},
      {name: "경남", regId: "11H20000",langcd:"07"},
      {name: "제주", regId: "11G00000",langcd:"08"}
    ];
    const { t }= useI18n() //번역필수 모듈
    const store = useStore();
    const router = useRouter();
    const mev0104 = computed(() => store.state.mev01.mev0104);
    const userData = computed(() => store.state.userData);
    const isLogin = computed(() => Object.keys(userData.value).length !== 0);
    const weatherLocationsOpen = ref(false);
    const selectedWeatherLocation = ref(weatherLocations[0]);
    const openToast = ref(false);
    const lang = ref();
    const usefeed = ref();
    const last_uptime = ref();
    const last_uptime_days = ref();
    const last_uptime_hours = ref();
    const date_reset = ref();
    onMounted(() => {
      if(store.state.app.lang === null){
        store.commit("app/SET_LANG", {
			    		lang: 'ko'
				});
      }
      lang.value = store.state.app.lang;
      usefeed.value = store.state.app.usefeed;
      i18n.global.locale.value = store.state.app.lang
      last_uptime.value = store.state.app.last_uptime;
      last_uptime_days.value = store.state.app.last_uptime.days;
      last_uptime_hours.value = store.state.app.last_uptime.hours;
      if(last_uptime.value !== null){
        if(Math.abs(parseInt(last_uptime_days.value)-parseInt(new Date().getDate()))>0){
            date_reset.value = "Y"
            store.commit('mhm01/SET_SELECT_DATE', {dateRange: {start: dayjs().toISOString(), end: dayjs().add(1, "day").toISOString()}});
        }else{
          if(Math.abs(parseInt(last_uptime_hours.value)-parseInt(new Date().getHours()))>2){
            date_reset.value = "Y"
            store.commit('mhm01/SET_SELECT_DATE', {dateRange: {start: dayjs().toISOString(), end: dayjs().add(1, "day").toISOString()}});
          }
        }
      }
      store.commit("app/SET_LAST_UPTIME", {
        // last_uptime: new Date().getHours()
        last_uptime:{
          days: new Date().getDate(),
          hours: new Date().getHours(),
          seconds: new Date().getSeconds(),
        }
      });
      const orderState = localStorage.getItem("orderState");
      if(orderState !== null) {
        router.push("/order/apply");
        return;
      }
      store.dispatch("mrm01/fetchEventList");
			store.dispatch("mev01/fetchMev0104",{
				proc_cd: "00",
				evNo: ""
			});
      store.dispatch("mrm01/fetchPlanList"); 
      store.dispatch("mrm01/fetchallEventList"); 
      
      store.dispatch("mhm01/fetchMhm0101", {lang: store.state.app.lang});
      try {
        store.dispatch('app/requestFCMappToken');
        window.receivedFCMappToken = function receivedFCMappToken(token) {
         store.commit("SET_FCM_TOKEN", { fcmToken: token.message["token"] });
        };
			} catch(e) {
        //console.log(e)
      }
    });
    if(userData.value.mem_id === undefined){
        store.commit("SET_USERDATA", {userData: {}});
    }
    watchEffect(() => {
      if(isLogin.value && selectedWeatherLocation.value !== undefined) {
        store.dispatch("mhm01/fetchWeather", {regId: selectedWeatherLocation.value.regId});
      }
    });
    watchEffect(async () => {
      if(isLogin.value && store.state.app.location.lat !== null && store.state.mhm01.selectedLocation === null) {
        const data = await mhm021.getMhm021({lati: store.state.app.location.lat, longi: store.state.app.location.lng});
        //console.log(data)
        if (data.data.length > 0) {
          store.commit("mhm01/SET_SELECT_LOCATION", {location: {area_cd1: data.data[0].AREA_CD.slice(0,2), area_cd2: data.data[0].AREA_CD, area_nm2: data.data[0].AREA_NM}});
        } else {
          store.commit("mhm01/SET_SELECT_LOCATION", {location: {area_cd1: "01", area_cd2: "0101", area_nm1: "서울", area_nm2: "강남/역삼/서초/삼성"}});
        }
      }
    })

    watch(isLogin, (isLogin) => {
      if (isLogin) {
        if(store.state.mhm01.selectedLocation === null){
          try {
				    store.dispatch("app/requestLocation");
			    } catch(e) {
			      navigator.geolocation.getCurrentPosition(
			    	function(position) {
			    	var posi_lat = position.coords.latitude;
			    	var posi_longi = position.coords.longitude;
            //console.log(posi_lat,posi_longi)
			    	store.commit("app/SET_LOCATION", {
			    		location: {
			    			lat: posi_lat,
			    			lng: posi_longi
			    		}
			    	});
			    	}, 
			    );
			}
        }
        if(store.state.mhm01.selectedDateRange === null) {
				  store.commit('mhm01/SET_SELECT_DATE', {dateRange: {start: dayjs().toISOString(), end: dayjs().add(1, "day").toISOString()}});
        }
        if(store.state.mhm01.selectedMember === null) {
          store.commit('mhm01/SET_MEMBER', {member: {adultCount: 2, childCount: 0}});
        }
        store.dispatch("mhm01/fetchMhm0102", {proc_cd: "02", login_id: userData.value.mem_token,lang:store.state.app.lang});
        store.dispatch("mfd01/fetchMfd01011", {proc_cd: "01", mem_id: userData.value.mem_token});
        store.dispatch("mrm01/fetchPopularRecommend");
      } else {
        store.dispatch("mhm01/fetchMhm0102", {proc_cd: "01",lang: store.state.app.lang});
        store.dispatch("mfd01/fetchMfd01011", {proc_cd: "01"});
      }
    }, {immediate: true})

    const handleWeatherLocationsOpen = () => {
      weatherLocationsOpen.value = !weatherLocationsOpen.value;
    };

    const handleWeatherLocationClick = (weatherLocation) => {
      selectedWeatherLocation.value = weatherLocation;
      weatherLocationsOpen.value = false;
    };
    const mhm0101Loading = computed(() => store.state.mhm01.mhm0101Loading);
    const mhm0102Loading = computed(() => store.state.mhm01.mhm0102Loading);
    const mfd01011Loading = computed(() => store.state.mfd01.mfd01011Loading);
    const eventListLoading = computed(() => store.state.mrm01.eventListLoading);
    const planListLoading = computed(() => store.state.mrm01.planListLoading);
    const alleventListLoading = computed(() => store.state.mrm01.alleventListLoading);
    const popularRecommendLoading = computed(() => store.state.mrm01.popularRecommendLoading);
  
    const completed = computed(() => {
      let loading = mhm0102Loading.value || mfd01011Loading.value || eventListLoading.value || planListLoading.value || alleventListLoading.value;
      if (isLogin.value){
        loading = loading || popularRecommendLoading.value;
      } else {
        loading = loading || mhm0101Loading.value;
      }
      return !loading;
    });

    const findHotel = () => {
      if(store.state.mhm01.selectedLocation === null) {
        openToast.value = true;
        return;
      }
      if(store.state.mhm01.selectedDateRange === null) {
        openToast.value = true;
        return;
      }
      if(store.state.mhm01.selectedMember === null) {
        openToast.value = true;
        return;
      }
      router.push("/rooms/list");
    }
    return {
      mhm0101: computed(() => store.state.mhm01.mhm0101),
      mhm0102: computed(() => store.state.mhm01.mhm0102),
      mfd01011: computed(() => store.state.mfd01.mfd01011),
      selectedLocation: computed(() => store.state.mhm01.selectedLocation),
      dateRangeText: computed(() =>
        store.state.mhm01.selectedDateRange !== null 
          ? dayjs(store.state.mhm01.selectedDateRange.start).format("MM.DD") +
              " ~ " +
              dayjs(store.state.mhm01.selectedDateRange.end).format("MM.DD")
          : null
      ),
      selectedMember: computed(() => store.state.mhm01.selectedMember),
      isLogin,
      userData,
      weatherLocationsOpen,
      weather: computed(() => store.state.mhm01.weather.map(weatherItem => ({
        ...weatherItem,
        date: dayjs(weatherItem.date).format("MM/DD"),
        dayOfTheWeek: dayjs(weatherItem.date).format("dd"),
        text: weatherTextList[weatherItem.id],
      }))),
      handleWeatherLocationsOpen,
      weatherLocations,
      handleWeatherLocationClick,
      selectedWeatherLocation,
      completed,
      eventList: computed(() => store.state.mrm01.eventList),
      alleventList: computed(() => store.state.mrm01.alleventList),
      planList: computed(() => store.state.mrm01.planList),
      popularRecommend: computed(() => store.state.mrm01.popularRecommend),
      numberWithCommas,
      openToast,
      mev0104,
      lang,
      goSbdeajeon() {
        router.push('/layers/Sbdeajeon')
      },
      t,  //번역필수 모듈
		  i18n,
      handleCloseToast() {
        openToast.value = false;
      },
      findHotel,
      usefeed
    };
  },
};
</script>
